<template>
  <div name="planningedit">
    <transition name="modalwindow">
      <div
        class="modal-mask fixed top-0 left-0 w-full h-full outline-none fade"
      >
        <div
          class="modal-container bg-white w-9/12 my-10 mx-auto rounded shadow-lg z-50 h-full "
        >
          <div class="pt-2">
            <slot name="header">
              <i
                class="fas fa-times-circle cursor-pointer fixed right-0 items-center mt-3  mr-48 text-pink-red text-3xl z-50"
                @click="$emit('close')"
              ></i>
              <h2 class="text-2xl m-3 text-center text-pink-red">
                {{ title }}
              </h2>
            </slot>
          </div>
          <div class="h-screen overflow-y-auto">
            <slot name="body">
              <div v-if="customUnitsLoaded" class="mb-8">
                <h2
                  class="text-lg ml-10 m-2 font-bold text-gray-700 capitalize"
                >
                  My Units
                </h2>

                <table class="table-wrapper w-11/12 h-full mx-auto mb-4">
                  <tbody>
                    <tr class="course" v-for="item in customUnits">
                      <td class="w-1/2">
                        <strong>{{ item.name }}</strong>
                      </td>
                      <td class="w-1/4">
                        <div
                          v-for="cat in cats"
                          v-if="cat.id == item.category_id"
                        >
                          {{ cat.name }}
                        </div>
                      </td>

                      <td>
                        <button
                          type="button"
                          @click="addNewPlan(item)"
                          class="icon fa-check-circle bg-green-400 hover:bg-green-600 text-white text-xs font-bold py-1 px-2 rounded"
                        >
                          Select for {{ term.name }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>

              <div v-if="moduleUnitsLoaded" class="mb-8">
                <h2
                  class="text-lg ml-10 m-2 font-bold text-gray-700 capitalize"
                >
                  Pippa and Eddie
                </h2>

                <table class="table-wrapper w-11/12 h-full mx-auto mb-4">
                  <tbody>
                    <tr class="course" v-for="item in moduleUnits">
                      <td class="w-1/2">
                        <strong>{{ item.name }}</strong>
                      </td>
                      <td class="w-1/4">
                        <div
                          v-for="cat in cats"
                          v-if="cat.id == item.category_id"
                        >
                          {{ cat.name }}
                        </div>
                      </td>

                      <td>
                        <button
                          type="button"
                          @click="addNamePlanWithCheck(item)"
                          class="icon text-white text-xs font-bold py-1 px-2 rounded"
                          v-bind:class="setModuleStyle(item)"
                        >
                          Select for {{ term.name }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>

              <div v-if="componentLoaded" class="mb-8">
                <h2
                  class="text-lg ml-10 m-2 font-bold text-gray-700 capitalize"
                >
                  Core Units
                </h2>

                <table class="table-wrapper w-11/12 h-full mx-auto mb-40">
                  <tbody>
                    <tr class="course" v-for="item in orderedUnits">
                      <td class="w-1/2">
                        <strong>{{ item.name }}</strong>
                      </td>
                      <td class="w-1/4">
                        <div
                          v-for="cat in cats"
                          v-if="cat.id == item.category_id"
                        >
                          {{ cat.name }}
                        </div>
                      </td>

                      <td>
                        <button
                          type="button"
                          @click="addNewPlan(item)"
                          class="icon fa-check-circle bg-green-400 hover:bg-green-600 text-white text-xs font-bold py-1 px-2 rounded"
                        >
                          Select for {{ term.name }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
              <p v-if="hasResults == false" class="m-4 font-bold text-sm">
                No units available for selected year, please select again
              </p>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SchoolService, BaseService } from "@/services/school.service";
import { termList } from "@/assets/js/utils";
import { yearGroupMixin} from "@/mixins/yearGroupMixin.js";

export default {
  name: "planningedit",
  mixins: [yearGroupMixin],
  props: {
    term: { type: Object, required: true },
    year: { type: Number, required: true, default: 0 },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
      settings: "settings",
    }),
    customUnitsLoaded() {
      return this.customUnits.length > 0;
    },
    moduleUnitsLoaded() {
      return this.moduleUnits.length > 0;
    },
    componentLoaded() {
      return this.orderedUnits.length > 0;
    },
    hasResults() {
      return (
        this.componentLoaded || this.customUnitsLoaded || this.moduleUnitsLoaded
      );
    },
    customUnits() {
      var vm = this;
      let type = vm.courseTypes.find((c) => c.key_value === "school");

      var filtered = vm.schoolUnits.filter(function (unit) {
        let filtered = true;

        filtered = unit.year_id == vm.year && unit.type_id === type.id;

        return filtered;
      });

      return this.$orderBy(filtered, ["name", "category_id"]);
    },
    orderedUnits() {
      var vm = this;
      let types = vm.courseTypes
        .filter((c) => c.key_value === "free" || c.key_value === "organisation")
        .map((obj) => obj.id);

      var filtered = vm.units.concat(vm.schoolUnits).filter(function (unit) {
        let filtered = true;

        filtered = unit.year_id == vm.year && types.includes(unit.type_id);

        return filtered;
      });
      return this.$orderBy(filtered, ["name", "category_id"]);
    },
    moduleUnits() {
      var vm = this;
      let type = vm.courseTypes.find((c) => c.key_value === "pippaeddie");
      var filtered = vm.units.filter(function (unit) {
        let filtered = true;

        filtered = unit.year_id == vm.year && unit.type_id === type.id;

        return filtered;
      });

      return this.$orderBy(filtered, ["name", "category_id"]);
    },
    title() {
      let vm = this;
      let year = this.years.find(function (y) {
        return y.id === vm.year;
      });
      return year ? year.name + " Planning Setup " : "";
    },
  },
  data: function () {
    return {
      terms: termList(),
      showModal: false,
      units: [],
      schoolUnits: [],
      cats: [],
      courseTypes: [],
    };
  },
  created() {
    // cats
    BaseService.getAll("categories")
      .then((response) => this.buildCategoryList(response.data))
      .catch((res) => {
        console.log(res);
      });
    BaseService.getAll("course-types")
      .then((response) => (this.courseTypes = response.data))
      .catch((res) => {
        console.log(res);
      });

    this.fetchBaseData();
    this.fetchSchoolData();
  },
  methods: {
    fetchBaseData() {
      let freeParam = this.settings.display.courses == "custom" ? "" : "free";

      BaseService.getAll("courses?types=pippaeddie," + freeParam)
        .then((response) => this.buildUnitList(response.data))
        .catch((err) => {
          console.log("Courses " + err);
          this.errored = true;
        });
    },
    fetchSchoolData() {
      SchoolService.getAll("courses")
        .then((response) => this.buildSchoolUnitList(response.data))
        .catch((err) => {
          console.log("Courses " + err);
          this.errored = true;
        });
    },
    buildUnitList(data) {
      this.units = data;
    },
    buildSchoolUnitList(data) {
      this.schoolUnits = data;
    },
    buildCategoryList(data) {
      this.cats = data;
    },
    hasTerm() {
      return false;
    },
    addNamePlanWithCheck(unit) {
      if (this.isAllowed(unit)) {
        this.addNewPlan(unit);
      }
    },
    addNewPlan(unit) {
      let payload = {
        //term: this.term.term_key,
        term: this.term.key,
        course_id: unit.id,
        year: this.year,
      };
      // this.planningAddition(unit);
      SchoolService.create("planning", payload)
        .then(() => {
          this.planningAddition(unit);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    planningAddition(data) {
      this.$emit("refresh", data);
    },
    isAllowed(unit) {
      let vm = this;
      let type = vm.courseTypes.find((c) => c.id === unit.type_id);
      return type != undefined && vm.currentAccess.hasModule(type.key_value);
    },
    setModuleStyle(unit) {
      if (this.isAllowed(unit)) {
        return "fa-check-circle bg-green-400 hover:bg-green-600";
      } else {
        return "fa-lock bg-orange-400 text-gray-200 hover:bg-orange-600 cursor-not-allowed";
      }
    },
  },
};
</script>

<style scoped></style>
